
import {
  computed,
  defineComponent,
} from 'vue';

import { IBreadcrumb } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'AtomPlug',

  props: {
    isTrack: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('AtomPlug.breadcrumbs.learning'),
        disabled: false,
        to: { name: Names.R_APP_LEARNING_TRACKS },
        'data-test-label': 'my-learning',
      },
      {
        text: t('AtomPlug.breadcrumbs.catalogue'),
        disabled: false,
        to: { name: Names.R_APP_LEARNING_CATALOGUE },
        'data-test-label': 'catalogue',
      },
    ]);
    const path = computed(() => ({ name: Names.R_APP_LEARNING_CATALOGUE }));
    const text = computed(() => {
      if (props.isTrack) {
        return {
          title: t('AtomPlug.title.track'),
          description: t('AtomPlug.description.track'),
        };
      }
      return {
        title: t('AtomPlug.title.atom'),
        description: t('AtomPlug.description.atom'),
      };
    });

    return {
      breadcrumbs,
      path,
      text,
    };
  },
});
